import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface IFormInput {
  firstName: string;
  lastName: string;
  profileTitle: string;
  bio: string;
}

const Onboarding1: React.FC = () => {
   const navigate = useNavigate(); // Initialize useNavigate hook
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFormInput>();

  const onSubmit = (data: IFormInput) => {
   navigate('/onboarding/2', { state: { data } });
  };

  return (
    <Box p={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.firstName} isRequired>
          <FormLabel htmlFor="firstName">First Name</FormLabel>
          <Input
            id="firstName"
            placeholder="John"
            {...register("firstName", { required: "First name is required" })}
          />
          <FormErrorMessage>
            {errors.firstName && errors.firstName.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.lastName} isRequired mt={4}>
          <FormLabel htmlFor="lastName">Last Name</FormLabel>
          <Input
            id="lastName"
            placeholder="Doe"
            {...register("lastName", { required: "Last name is required" })}
          />
          <FormErrorMessage>
            {errors.lastName && errors.lastName.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl mt={4}>
          <FormLabel htmlFor="profileTitle">Profile Title</FormLabel>
          <Input
            id="profileTitle"
            placeholder="Your professional headline"
            {...register("profileTitle")}
          />
        </FormControl>

        <FormControl mt={4}>
          <FormLabel htmlFor="bio">Bio</FormLabel>
          <Textarea
            id="bio"
            placeholder="Tell us more about yourself"
            {...register("bio")}
          />
        </FormControl>

        <Button mt={4} colorScheme="blue" isLoading={isSubmitting} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Onboarding1;
