import React, { FC } from 'react';
import { OnboardingWrapper } from './Onboarding.styled';

interface OnboardingProps {}

const Onboarding: FC<OnboardingProps> = () => (
 <OnboardingWrapper data-testid="Onboarding">
    Onboarding wrapper
 </OnboardingWrapper>
);

export default Onboarding;
