import React, { FC } from 'react';
import { LandingWrapper } from './Landing.styled';
import Features from '../Features/Features';
import Icon from '../../Icons';
import Spotlight from '../Spotlight/Spotlight';

const VideoBackground = () => {
   return (
     <div id="video-hero">
       <video autoPlay loop muted poster="images/video/poster.jpg">
         <source src={`${process.env.PUBLIC_URL}/images/video/video.mp4`} type="video/mp4" />
       </video>
     </div>
   );
 };




const MainPage = () => {
   
   return (
      <div>
         <section id="hero" className="hero">
            
            <div className="overlay"></div>
            
            <div className="container vertical-center-rel">
               <div className="row">
                  <div className="col-md-7">
                     <h1 className="text-white p-t-md">Libre and Open Source NFC Digital Business Cards</h1>
                     <p className="lead text-white m-b-md ">We enable you to create your very own DIY business card with our forever free software that you can self-host.</p>
                     <a href="#features" className="btn btn-shadow btn-blue text-uppercase btn-md smooth-scroll">Let's go!</a>
                     
                  </div>

                 
               </div>
               
            </div>
            <VideoBackground />
         </section>

         <Spotlight />

         <section id="features" className="p-y-lg">

            <div className="container">

               <div className="row">
                  <div className="col-md-12 col-md-offset-2">
                     <div className="section-header text-center wow fadeIn">
                        <h2>Core Functionality. TL:DR of the long tutorial.</h2>
                        <p className="lead">Our approach is simple, we help you generate a website that has all your contact information and guide you on how to embed that data into an NFC business card.</p>
                     </div>
                  </div>
               </div>

               <Features />
               </div>
         </section>

         <section id="work" className="p-y-lg">


            <div className="container">
               <div className="row">
                  <div className="col-md-12 col-md-offset-2">
                     <div className="section-header text-center wow fadeIn">
                        <h2>Card templates</h2>
                        <p className="lead">Here you have some card templates for laser engraving. They are provided as is made by us with copyleft. Now they are public domain. Please, if you want to contribute, send us some designs at nacho@cardable.org</p>
                     </div>
                  </div>
               </div>
            </div>

            <div className="container">
               <div className="row">

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Tech Coffee</h5>
                                 <p className="small">Liber theophrastus ea est, vel unum ubique feugait in. Has ne albucius tincidunt.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 1 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn" data-wow-delay="0.2s">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Image Filter</h5>
                                 <p className="small">Quodsi volutpat persequeris cu vel, sea te dolorum dissentiet. Per an dicunt causae, id ipsum.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 2 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn" data-wow-delay="0.4s">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Clean Sheet</h5>
                                 <p className="small">Rebum insolens vim ne, no per zril mnesarchum inciderint. Quo dicat audire interesset cu.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 3 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn" data-wow-delay="0.6s">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Desktop Sound</h5>
                                 <p className="small">Congue platonem ei usu, cu erroribus principes ius. Ea per case epicurei, iisque utroque corpora vis an.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 4 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn" data-wow-delay="0.8s">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Morning Code</h5>
                                 <p className="small">Volumus antiopam id his, nec et fabulas voluptua. Quot platonem maluisset vim et, cu accusam quaestio intellegat vel.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 5 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>

                  <div className="col-md-4 col-sm-6 m-b-md clearfix">
                     <div className="h caption-1 wow zoomIn" data-wow-delay="1s">

                        <figure><img src={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="img-responsive" alt="" />

                           <figcaption>
                              <div className="caption-box vertical-center-abs text-center">
                                 <h5>Black &amp; White</h5>
                                 <p className="small">Curabitur ultrices nec est nec vestibulum. Maecenas tincidunt pretium lacinia.</p>

                                 <a href={`${process.env.PUBLIC_URL}/images/card-example1.png`} className="btn btn-blue m-b-0 mp-gallery" title="Project 6 - with description">View More</a>
                              </div>
                           </figcaption>
                        </figure>
                     </div>
                  </div>
               </div>


               <div className="col-md-12 col-md-offset-2 text-center p-t-md wow fadeIn" data-wow-delay="0.8s">
                  <h4 className="m-t f-w-900">Want to see your own design featured here?</h4>
                  <p className="p-opacity m-b-md">Send a MR to the project in Gitlab or send me via email to nacho@nachologic.com the image and the .svg files and they will be featured here in the wall of honor.</p>
                  <a href="mailto:nacho@nachologic.com" className="btn btn-shadow btn-blue text-uppercase">Submit my work</a>
               </div>

            </div>
         </section>
      </div>

   );
};

export default MainPage;
