import React from 'react';
import Icon from '../../Icons';

const Footer: React.FC = () => {
  return (
    <footer className="p-y-md footer f1 bg-edit bg-grey">
      <div className="container">
      <footer id="footer1-1" className="p-y-md footer f1 bg-edit bg-grey">
            <div className="container">
                    
                    <div className="col-sm-12">
                        <p>All rights reserved - Copyright &copy; 2024 Cardable.org by <a href="http://cardable.org" className="f-w-900">Cardable</a></p>
                    </div>
                    
                    <div className="col-sm-12">
                        <ul className="footer-social">
                            <li><a href="#" className="social-button"><Icon icon="linkedin2" size={24} color="white"/></a></li>
                            <li><a href="#" className="social-button"><Icon icon="connection" size={24} color="white"/></a></li>
                            <li><a href="#" className="social-button"><Icon icon="instagram" size={24} color="white"/></a></li>
                        </ul>
                    </div>
        
            </div>
        </footer>
      </div>
    </footer>
  );
};

export default Footer;