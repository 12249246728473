import React, { FC } from 'react';
import { Onboarding4Wrapper } from './Onboarding4.styled';

interface Onboarding4Props {}

const Onboarding4: FC<Onboarding4Props> = () => (
 <Onboarding4Wrapper data-testid="Onboarding4">
    Onboarding4 Component
 </Onboarding4Wrapper>
);

export default Onboarding4;
