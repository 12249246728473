import React, { FC } from 'react';
import { Onboarding3Wrapper } from './Onboarding3.styled';

interface Onboarding3Props {}

const Onboarding3: FC<Onboarding3Props> = () => (
 <Onboarding3Wrapper data-testid="Onboarding3">
    Onboarding3 Component
 </Onboarding3Wrapper>
);

export default Onboarding3;
