import React from 'react';
import logo from './logo.svg';
import './App.css';
import Onboarding1 from './components/Onboarding/Onboarding1/Onboarding1';
import Onboarding2 from './components/Onboarding/Onboarding2/Onboarding2';
import Onboarding3 from './components/Onboarding/Onboarding3/Onboarding3';
import Onboarding4 from './components/Onboarding/Onboarding4/Onboarding4';
import Landing from './components/Landing/Landing';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import { Routes, Route } from 'react-router-dom';
import Onboarding from './components/Onboarding/Onboarding';




function App() {
  return (
    <div className="App App-header">
      <Header />
      <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/onboarding" element={<Onboarding />}>
        <Route path="1" element={<Onboarding1 />} />
        <Route path="2" element={<Onboarding2 />} />
        <Route path="3" element={<Onboarding3 />} />
        <Route path="4" element={<Onboarding4 />} />
      </Route>
    </Routes>
      <Footer />
    </div>
  );
}

export default App;
