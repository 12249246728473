import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding-top: 3rem;
`;

const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: medium;
`;

const ImgWrap = styled.div<{ bgImage: string }>`
  width: auto;
  height: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${(props) => props.bgImage});
`;

const TextWhiteDiv = styled.div`
  background-color: #007bff; /* Example blue color */
  color: white;
`;

const ButtonGhost = styled.a`
  border: 1px solid white;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 12px;
  text-decoration: none;
  
    background-color: white;
    color: #007bff;
  
`;

const ContentSpotlight: React.FC = () => {
  return (
    <Section id="solutions" className="content-spotlight">
      <div className="container">
        <div className="row">
          <div className="col-md-12  col-md-offset-2">
            <SectionHeader className="section-header">
              <h2>Do you need more than one or prefer a more personalized user experience?</h2>
              <p className="lead">Perhaps you need more functionalities such as calendar or CRM integration. At <a href="https://cardable.tech">cardable.tech</a>, we will be more than happy to help.</p>
            </SectionHeader>
          </div>
        </div>
      </div>

      <div className="container p-a-0">
        <div className="row m-a-0 spotlight-block img-left">
          <figure className="col-md-6 p-a-0">
            <ImgWrap bgImage={`${process.env.PUBLIC_URL}/images/banner-cardable.png`} />
          </figure>
          <TextWhiteDiv className="col-md-6 spotlight-text center-md">
            <div className="vertical-center-rel">
              <h6>About us</h6>
              <h3>A Creative Agency where technology moves people.</h3>
              <p>Since 2019 we've been experimenting with QR codes and NFC for contact information exchange. We can provide help with everything related to marketing in regards to NFC and QR codes.</p>
              <ButtonGhost href="https://cardable.tech">Hire us</ButtonGhost>
            </div>
          </TextWhiteDiv>
        </div>
      </div>
    </Section>
  );
};

export default ContentSpotlight;
