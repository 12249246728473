import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

type FormData = {
  personalEmail: string;
  professionalEmail: string;
  phoneNumber: string;
  professionalNumberExt: string;
};

const Onboarding2: React.FC = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const { handleSubmit, register, formState: { errors } } = useForm<FormData>();
  const toast = useToast();

  const onSubmit = (data: FormData) => {
    // Here you would usually send the data to a backend server or another component
    // For demonstration, we'll just show a toast message
    toast({
      title: 'Form submitted!',
      description: JSON.stringify(data, null, 2),
      status: 'success',
      duration: 9000,
      isClosable: true,
    });

    // Example of navigating to another component
    navigate('/onboarding3', { state: { data } });
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!errors.personalEmail} mb={4}>
          <FormLabel htmlFor="personalEmail">Personal Email</FormLabel>
          <Input id="personalEmail" type="email" {...register('personalEmail', { required: 'This field is required', pattern: /^\S+@\S+$/i })} />
          <FormErrorMessage>
            {errors.personalEmail && errors.personalEmail.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.professionalEmail} mb={4}>
          <FormLabel htmlFor="professionalEmail">Professional Email</FormLabel>
          <Input id="professionalEmail" type="email" {...register('professionalEmail', { required: 'This field is required', pattern: /^\S+@\S+$/i })} />
          <FormErrorMessage>
            {errors.professionalEmail && errors.professionalEmail.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.phoneNumber} mb={4}>
          <FormLabel htmlFor="phoneNumber">Phone Number</FormLabel>
          <Input id="phoneNumber" type="tel" {...register('phoneNumber', { required: 'This field is required' })} />
          <FormErrorMessage>
            {errors.phoneNumber && errors.phoneNumber.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.professionalNumberExt} mb={4}>
          <FormLabel htmlFor="professionalNumberExt">Professional Number Ext.</FormLabel>
          <Input id="professionalNumberExt" {...register('professionalNumberExt')} />
          <FormErrorMessage>
            {errors.professionalNumberExt && errors.professionalNumberExt.message}
          </FormErrorMessage>
        </FormControl>

        <Button mt={4} colorScheme="teal" isLoading={false} type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default Onboarding2;