import React, { useState } from 'react';
import './Features.css'; // Make sure to create and import your CSS for styling

interface Tab {
  id: string;
  title: string;
  content: JSX.Element;
}

const tabs: Tab[] = [
   {
      id: 'tab1',
      title: 'Profile creation',
      content: (
        <div>
          <div className="row text-center">
            <div className="col-md-12">
              <h4>You will create a profile</h4>
              <p className="p-tab m-b-md">Don't worry, we won't store any of your data.</p>
            </div>
          </div>
          <div className="row y-middle c2">
            <div className="col-md-12">
              <div className="features-block">
                <div className="col-sm-12 icon-left m-b-md clearfix">
                  <i className="icon-target2"></i>
                  <h5 className="m-t f-w-900">Contact buttons</h5>
                  <p>You will have certain contact action buttons which will reduce friction to make contact after you exchange information.</p>
                </div>
                <div className="col-sm-12 icon-left clearfix">
                  <i className="icon-strategy"></i>
                  <h5 className="m-t f-w-900">Digital Strategy</h5>
                  <p>This is going to help you get more leads into your profile and products since they already have everything to reach you on their phone without any effort.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 'tab2',
      title: 'Download the generated website',
      content: (
        <div>
          <div className="row text-center">
            <div className="col-md-12">
              <h4>Once you fill all the data you want to have in your card, a zip file will be generated with all your data contained inside a website.</h4>
              <p className="p-tab m-b-md">You will have to host this website in one of the many places where you can host a website, we recommend firebase because it has a free tier, but you can self host it on your own server or however you want ^^</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-md-offset-2">
              <img src="images/giphy.gif" className="img-responsive m-x-auto" alt="" />
            </div>
          </div>
        </div>
      ),
    },
    {
      id: 'tab3',
      title: 'Time to embed the data into the card',
      content: (
        <div>
          <div className="row text-center">
            <div className="col-md-12">
              <h4>Embed the generated URL into the NFC business card</h4>
              <p className="p-tab m-b-md">Now it is a good time to also personalize the card, if you are using a wooden card, laser engrave it; if it's plastic, then you already know.</p>
            </div>
          </div>
          <div className="row video y-middle c2">
            <div className="col-md-4">
              <img src="images/giphy2.gif" alt="" className="img-responsive m-x-auto" />
            </div>
            <div className="col-md-12">
              <h5 className="f-w-900">In no time you will be tapping and exchanging your contact information like a boss</h5>
              <p>Check with your phone if everything went well. Once you tap your website should be open or ask to be open and you will be greeted with your contact information.</p>
            </div>
          </div>
        </div>
      ),
    }
];

const Features: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].id);

  return (
    <div className="features-tab">
      <div className="tab-titles">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-title ${activeTab === tab.id ? 'current' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            <p className="f-w-900 m-b-0">{tab.title}</p>
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs.filter((tab) => tab.id == activeTab)[0].content}
      </div>
    </div>
  );
};

export default Features;
