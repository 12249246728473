import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as TogglerIcon } from './menu.svg';


const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 60);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNavbar = () => setIsNavCollapsed(!isNavCollapsed);

  const scrollToSection = (sectionId: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setIsNavCollapsed(true);
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <nav className={`navbar fixed-top navbar-expand-lg ${isScrolled ? 'navbar-dark' : 'navbar-light'}`}>
      <div className="container">
        <NavLink to="/" className="navbar-brand" onClick={(e) => {
          e.preventDefault();
          window.scrollTo(0, 0);
        }}>
          <img src={isScrolled ? `${process.env.PUBLIC_URL}/images/logo-black.png` : `${process.env.PUBLIC_URL}/images/logo-white.png`} alt="Logo" />
        </NavLink>
        <button className="navbar-toggler" type="button" aria-expanded={!isNavCollapsed ? 'true' : 'false'} aria-label="Toggle navigation" onClick={toggleNavbar}>
          <TogglerIcon fill={isScrolled ? 'black' : 'white'} />
        </button>
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* Note: Removed 'href' attribute and used 'onClick' for handling navigation */}
            <li className="nav-item">
              <a className="nav-link" onClick={(e) => scrollToSection('features', e)}>Your DIY NFC card</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={(e) => scrollToSection('solutions', e)}>Tailored solutions</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={(e) => scrollToSection('work', e)}>Templates</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={(e) => scrollToSection('team', e)}>Who we are</a>
            </li>
            <li className="nav-item">
              <a href="https://cardable.tech" className="nav-link">Hire us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
